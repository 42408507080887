import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setLocale } from 'yup';

setLocale({
  mixed: {
    default: ({ label }) => ({ key: 'VALIDATION_ERROR_FIELD_INVALID', label }),
    required: ({ label }) => ({ key: 'VALIDATION_ERROR_FIELD_REQUIRED', label }),
    notType: ({ label, type }) => ({ key: `VALIDATION_ERROR_FIELD_NOT_TYPE_${type.toUpperCase()}`, label }),
  },
  array: {
    min: ({ label, min }) => ({ key: 'VALIDATION_ERROR_ARRAY_MIN', label, values: { min } }),
  },
});

/**
 * A simple hook for validating form values using Yup schema
 * @param schema {import('yup').ObjectSchema}
 */
export function useYupValidation(schema) {
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const validate = (values, context) => {
    try {
      schema.validateSync(values, { abortEarly: false, context });

      setErrors({});
      return true;
    } catch (error) {
      const errors = {};

      error.inner?.forEach((error) => {
        const { key, label, values } = error.errors[0];
        errors[error.path] = t(key, { label: t(label), ...values });
      });

      setErrors(errors);
      return false;
    }
  };

  const isFieldRequired = (name, options) => {
    const field = schema.describe(options).fields[name];

    if (!field) {
      throw new Error(`Field "${name}" not found in schema`);
    }

    return !field.optional;
  };

  return { validate, errors, isFieldRequired };
}
