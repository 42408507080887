import nanoid from 'nanoid';
import { format } from 'date-fns';

const countryCurrencies = {
  li: 'CHF',
  cz: 'CZK',
  dk: 'DKK',
  gb: 'GBP',
  hu: 'HUF',
  no: 'NOK',
  pl: 'PLN',
  ro: 'RON',
  se: 'SEK',
};

export const recipientCountries = {
  de: 'Germany',
  gb: 'United Kingdom',
  fr: 'France',
  it: 'Italy',
  es: 'Spain',
  pl: 'Poland',
  ua: 'Ukraine',
  ro: 'Romania',
  nl: 'Netherlands',
  be: 'Belgium',
  cz: 'Czech Republic',
  se: 'Sweden',
  pt: 'Portugal',
  gr: 'Greece',
  hu: 'Hungary',
  at: 'Austria',
  by: 'Belarus',
  ch: 'Switzerland',
  bg: 'Bulgaria',
  rs: 'Serbia',
  dk: 'Denmark',
  fi: 'Finland',
  no: 'Norway',
  sk: 'Slovakia',
  ie: 'Ireland',
  hr: 'Croatia',
  ba: 'Bosnia and Herzegovina',
  md: 'Moldova',
  lt: 'Lithuania',
  al: 'Albania',
  si: 'Slovenia',
  lv: 'Latvia',
  mk: 'North Macedonia',
  ee: 'Estonia',
  lu: 'Luxembourg',
  me: 'Montenegro',
  mt: 'Malta',
  is: 'Iceland',
  ad: 'Andorra',
  li: 'Liechtenstein',
  mc: 'Monaco',
  sm: 'San Marino',
  va: 'Holy See',
};

export function createEmptyInfoItem() {
  return {
    id: nanoid(5),
    amount: '1',
  };
}

function getDate(strDate) {
  return strDate ? new Date(strDate) : null;
}

export function formatDate(date) {
  return format(date, 'dd.MM.yyyy');
}

export function getCurrencyOfCountry(countryCode) {
  return countryCurrencies[countryCode] || 'EUR';
}

export function createClaimFromShipment(shipment) {
  return {
    shipmentId: shipment.id,
    trackingCode: shipment.trackingCode,
    carrierCode: shipment.carrier.code,
    carrierCountryCode: shipment.carrier.countryCode,
    firstHubScanDate: getDate(shipment.firstHubScanDate),
    ssHubScanDate: getDate(shipment.ssHubScanDate),
    shipmentCreationDate: getDate(shipment.creationDate),
    deliveryDate: getDate(shipment.deliveryDate),
    postalReturnDeliveryDate: getDate(shipment.postalReturnDeliveryDate),
    postalReturnDate: getDate(shipment.postalReturnDate),
    isDelivered: !!shipment.deliveryDate || !!shipment.postalReturnDeliveryDate,
    isReturnShipment: shipment.isReturnShipment,
    deliveryAttemptDate: getDate(shipment.deliveryAttemptDate),
    deliveredToMailboxDate: getDate(shipment.deliveredToMailboxDate),
    weight: shipment.weight || 1,
    netValueCurrency: getCurrencyOfCountry(shipment.customer.country.iso2),
    partialDamageInfo: [createEmptyInfoItem()],
    missingItemInfo: [createEmptyInfoItem()],
    trackingScreenshot: [],
    invoiceDocuments: [],
    customerComment: '',
    declarationOfRecipient: [],
    picturesOfDamage: [],
    picturesOfMissingItem: [],
    recipientName: getRecipientFieldValue(shipment, 'name'),
    recipientAddress: getRecipientFieldValue(shipment, 'address'),
    recipientCity: getRecipientFieldValue(shipment, 'city'),
    recipientZipCode: getRecipientFieldValue(shipment, 'zipCode'),
    recipientCountryCode: getRecipientFieldValue(shipment, 'countryCode'),
    shopOrderId: shipment.order.shopOrderId,
    deadlines: shipment.deadlines,
    claimableWarnings: shipment.claimableWarnings,
    shipmentType: shipment.isReturnShipment ? 'return' : 'outbound',
  };
}

function getRecipientFieldValue(shipment, field) {
  const recipient = shipment.isReturnShipment ? shipment.order.recipient : shipment.customer;
  return recipient[field] || shipment.customer[field];
}

const eventTypeToClaimType = {
  SELECT_INVESTIGATION: 'investigation',
  SELECT_PARTIAL_DAMAGE: 'partial_damage',
  SELECT_WHOLE_DAMAGE: 'whole_damage',
  SELECT_MISSING_ITEM: 'missing_item',
};

/**
 * Gets the claim type based on claim or state machine's event type (after claim type select transitions)
 */
function getClaimTypeWithEventFallback(claim, meta) {
  const eventType = meta?.state?.event?.type;
  return eventType ? eventTypeToClaimType[eventType] || claim.type : claim.type;
}

export function isWarehouseDeliveryDateRequired(claim, _, meta) {
  const claimType = getClaimTypeWithEventFallback(claim, meta);
  const { useWarehouseDelivery, outcome } = claim.deadlines?.[claimType] || {};

  if (!useWarehouseDelivery) {
    return false;
  }

  return outcome === 'early' || outcome === 'past';
}

export function isNonManual(claim) {
  return !!claim.shipmentId;
}

export function getClaimableWarning(claim, meta) {
  const claimType = getClaimTypeWithEventFallback(claim, meta);
  return claim.claimableWarnings?.[claimType] || null;
}

export function isClaimWithMissingTrackingData(claim, _, meta) {
  return getClaimableWarning(claim, meta) === 'no_required_tracking_data';
}

export function isClaimOutsideOfDeadline(claim, _, meta) {
  const claimType = getClaimTypeWithEventFallback(claim, meta);
  const { outcome } = claim.deadlines?.[claimType] || {};

  // it's better to not compare against "within" because for manual flow we don't have deadlines
  return outcome === 'early' || outcome === 'past';
}

export function isClaimWithMailboxDeliveryWarning(claim, _, meta) {
  return getClaimableWarning(claim, meta) === 'mailbox_delivery_no_liability';
}

/**
 * Unlike FHS, claimable checks for Delivery Date are done on the client side
 * We can check claimableWarnings for any non-investigation flow which will indicate that flag is enabled
 */
export function isMissingDeliveryDateAllowed(claimOrShipment) {
  return claimOrShipment.claimableWarnings?.['whole_damage'] === 'no_required_tracking_data';
}
